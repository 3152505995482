import { Environment } from '../models';

const testingMode = false;

const signalRv3 = 'https://cdnjs.cloudflare.com/ajax/libs/microsoft-signalr/3.1.3/signalr.min.js';

// a name by which we can reference the current envirnoment
const id = '#{apiEnvironment}#';

// prettier-ignore
export const environment: Environment = {
  id,
  version: '#{Build.BuildNumber}#',
  testingMode,
  doctors: {
    api: '#{doctors.api}#',
    newApi: '#{doctors.newApi}#',
    sessionsAPI: '#{doctors.sessionsApi}#',
    recordsAPI: '#{doctors.recordsApi}#',
    pharmaAPI: '#{doctors.pharmaceuticalApi}#',
    pharmacyAPI: '#{doctors.pharmacyApi}#',
    prescriptionAPI: '#{doctors.prescriptionApi}#',
    mq: {
      domainv3: '#{doctors.mq.domainV3}#',
      signalRv3,
    },
    rbac:'#{doctors.rbac}#',
    cardReaderEnv: ['#{doctors.cardReaderEnv}#'],
  },
  production: true,
  appInsights: {
    config: {
      instrumentationKey: '#{appInsights.key}#'
    }
  },
  google: {
    cliniciansGtm: '#{google.cliniciansGtm}#'
  },
  sentry: {
    environment: id
  },
  zendesk: {
    key: '#{zendesk.key}#',
  },
};
